const BASE_URL = process.env.REACT_APP_BASE_URL;

const LOGIN_URL = `${BASE_URL}/login`;
const USER_LANGUAGES_URL = `${BASE_URL}/user/languages`;
const COIN_PACKAGES_URL = `${BASE_URL}/webcoinpackages`;
const COINS_PURCHASE_URL = `${BASE_URL}/coin_purchase_history`;
const PAYMENT_REQUEST_URL = `${BASE_URL}/buy_web_coins`;
const COIN_BALANCE_URL = `${BASE_URL}/coin_balance`;
const VERIFY_TOKEN_FROM_MOB_APP_URL = `${BASE_URL}/get_usertkn_details`;
const FLIX_PACKAGES_URL = `${BASE_URL}/flax_rates_web`;
const FLIX_PURCHASE_URL = `${BASE_URL}/flax_purchased_history`;
const FLIX_BALANCE_URL = `${BASE_URL}/coin_balance`;
const PAYMENT_FLIX_REQUEST_URL = `${BASE_URL}/buy_flix_web`;
// const FLIX_USERS = `${BASE_URL}/user/homeapp/operations/beneficiarylist`
const FLIX_USERS = `${BASE_URL}/user/username-search`;
const FLIX_PURCHASE_FOR_OTHER_USER_URL = `${BASE_URL}/flax_purchased_history`;

const STRIPE_PAYMENT_INTENT_URL = `${BASE_URL}/stripe/createpaymentintent`;

const API_STATUS = {
  LOADING: "loading",
  SUCCESS: "success",
  ERROR: "error",
} as const;

const PAGINATION_DEFAULT = {
  SIZE: 20,
  PAGE: 1,
} as const;

export {
  LOGIN_URL,
  USER_LANGUAGES_URL,
  COIN_PACKAGES_URL,
  API_STATUS,
  COINS_PURCHASE_URL,
  PAYMENT_REQUEST_URL,
  COIN_BALANCE_URL,
  VERIFY_TOKEN_FROM_MOB_APP_URL,
  FLIX_PACKAGES_URL,
  FLIX_BALANCE_URL,
  FLIX_PURCHASE_URL,
  PAYMENT_FLIX_REQUEST_URL,
  PAGINATION_DEFAULT,
  FLIX_USERS,
  FLIX_PURCHASE_FOR_OTHER_USER_URL,
  STRIPE_PAYMENT_INTENT_URL,
};
