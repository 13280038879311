import { STRIPE_PAYMENT_INTENT_URL } from "@constants/api-constansts";
import { http } from "@service/http.service";
import { getUrlWithParam } from "@util/util";
import { useMutation } from "react-query";

function createPaymentIntent({ body }: { body: any }) {
  const url = getUrlWithParam(`${STRIPE_PAYMENT_INTENT_URL}`, "");
  return http.post(
    { url, headers: { "Content-Type": "application/json" } },
    body
    // { auth: true, responseType: "text" }
  );
}

const useStripePaymentIntent = () => {
  const mutation = useMutation(createPaymentIntent);
  return mutation;
};

export default useStripePaymentIntent;
