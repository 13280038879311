import React, { useEffect, useState } from "react";
import { PaymentElement, useStripe } from "@stripe/react-stripe-js";
import Success from "@components/tw/common/payment/Success";
import Failure from "@components/tw/common/payment/Failure";
import Cancel from "@components/tw/common/payment/Cancel";

import { PaymentIntent } from "@stripe/stripe-js";

import { Navigate, useNavigate } from "react-router";

import Loader from "@components/tw/loader/Loader";
import { useClientSecret } from "@contexts/clientSecretProvider";

const PaymentStatus = () => {
  const stripe = useStripe();
  const [paymentIntent, setPaymentIntent] = useState<PaymentIntent | null>(
    null
  );
  const [loading, setLoading] = useState(true);
  // const [paymentIntentId, setPaymentIntentId] = useState<string | null>(null);
  // const [paymentStatus, setPaymentStatus] = useState("");
  const [error, setError] = useState("");
  const { clientSecret, setClientSecret } = useClientSecret();
  const navigate = useNavigate();

  const query = new URLSearchParams(window.location.search);

  const paymentIntentId = query.get("payment_intent_client_secret");
  const paymentStatus = query.get("redirect_status");
  console.log("12", paymentIntentId);
  console.log("wwd", paymentIntent);

  const fetchPaymentIntent = async () => {
    try {
      setLoading(true);
      const result = await stripe?.retrievePaymentIntent(paymentIntentId || "");
      console.log("res", result);
      if (result?.paymentIntent) {
        setPaymentIntent(result.paymentIntent);
      } else {
        setError("Payment intent not found.");
      }
    } catch (error) {
      setError("Failed to retrieve payment details.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("Stripe object:", stripe);
    console.log("Payment Intent ID:", paymentIntentId);
    setClientSecret("");
    fetchPaymentIntent();
    if (stripe && paymentIntentId) {
      fetchPaymentIntent();
    } else {
      console.log("errroro");
      setError("No payment intent ID found.");
    }
  }, [stripe, paymentIntentId]);

  // Handle direct access to /home/payment-status
  useEffect(() => {
    if (!paymentIntentId || !paymentStatus) {
      navigate("/home");
    }
  }, [paymentIntentId, paymentStatus, navigate]);

  const renderPaymentStatus = () => {
    switch (paymentStatus) {
      case "succeeded":
        return (
          <Success
            coins={paymentIntent?.description || ""}
            amount={paymentIntent?.amount}
            currency={paymentIntent?.currency || ""}
            tracking_id={paymentIntent?.id}
          />
        );
      case "failed":
        return (
          <Failure
            message={paymentIntent?.last_payment_error?.message || ""}
            coins={paymentIntent?.description || ""}
            amount={paymentIntent?.amount}
            currency={paymentIntent?.currency || ""}
            tracking_id={paymentIntent?.id}
          />
        );
      case "cancelled":
        return <Cancel message={paymentIntent?.cancellation_reason} />;
      case "processing":
        return <div>Processing...</div>;
      default:
        navigate("/home");
    }
  };

  if (loading) {
    return <Loader />;
  }

  // if (error) {
  //   return <div>Error: {error}</div>;
  // }

  return <>{!loading && paymentIntent && <div>{renderPaymentStatus()}</div>}</>;
};

export default PaymentStatus;
